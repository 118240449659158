import { useState, useEffect } from "react";
import {
  getDatabase,
  ref,
  onValue,
  off,
  update,
  increment,
} from "firebase/database";
import "bootstrap-icons/font/bootstrap-icons.css";

import ReactGA from "react-ga4";

export function LikeButton(props: { id: string }) {
  const [like, setLike] = useState(false);
  const [numLikes, setNumLikes] = useState(0);

  const storageId = `porch-${props.id}`;

  const db = getDatabase();
  const refData = ref(db, `likes/${props.id}`);

  useEffect(() => {
    const localLike = localStorage.getItem(storageId);
    setLike(localLike === "true" ? true : false);

    onValue(refData, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setNumLikes(data.numLikes);
      }
    });

    return () => {
      off(refData);
    };
  }, [storageId, refData]);

  const onLike = () => {
    const userAgent = navigator.userAgent;
    const log = "log/" + getDate();
    if (like === false) {
      setLike(true);
      setNumLikes(numLikes + 1);
      localStorage.setItem(storageId, "true");

      const updates = {
        numLikes: numLikes ? increment(1) : 1,
        [log]: `T: ${userAgent}`,
      };
      update(refData, updates);
      ReactGA.event("like", {
        id: props.id,
        set: true,
      });
    } else {
      setLike(false);
      setNumLikes(numLikes - 1);
      localStorage.setItem(storageId, "false");

      const updates = {
        numLikes: increment(-1),
        [log]: `F: ${userAgent}`,
      };
      update(refData, updates);
      ReactGA.event("like", {
        id: props.id,
        set: false,
      });
    }
  };

  return (
    <>
      <div className="py-3" id="like" onClick={onLike}>
        {like ? (
          <i
            className="bi bi-suit-heart-fill color-pink me-2"
            style={{ color: "#fa5e9a" }}
          ></i>
        ) : (
          <i className="bi bi-suit-heart me-2"></i>
        )}
        {numLikes == 0 ? "-" : numLikes}
      </div>
    </>
  );
}

function getDate() {
  const date = new Date();

  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2) +
    "T" +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2) +
    "+09:00"
  );
}
