export default function Works() {
  return (
    <>
      <h2> Works</h2>
      <div>
        <a href="/2023-08_polar-bear/">Polar Bear</a>
      </div>
      <div>
        <a href="/2023-05_rainy-days-and/">Rainy Days And...</a>
      </div>
      <div>
        <a href="/2023-05_an-egg/">An Egg</a>
      </div>

      <div>
        <a href="/2023-03_school-of-fish/">School of Fish</a>
      </div>

      <div>
        <a href="/2023-01_rabbit/">Rabit</a>
      </div>

      <div>
        <a href="/2022-11_specimen-of-authumn/">Specimen of Autumn</a>
      </div>

      <div>
        <a href="/2022-08_kawasemi/">Kawasemi</a>
      </div>

      <div>
        <a href="/2022-06_assortment_of_june/">Assortment of June</a>
      </div>
    </>
  );
}
