import React from "react";
import { LikeButton } from "../../components/LikeButton";
import usePageTracking from "../../useTracking";

import img1 from "./images/img1.jpg";
import img2 from "./images/img2.jpg";
import img1_sm from "./images/img1-sm.jpg";
import img2_sm from "./images/img2-sm.jpg";

export default function Page() {
  usePageTracking();
  return (
    <>
      <div className="row mb-0">
        <div className="col mx-0 px-0">
          <a href={img1_sm} target="_blank" rel="noopener noreferrer">
            <img src={img1} className="img-fluid" alt="枯山水1" />
          </a>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col mx-0 px-0">
          <a href={img2_sm} target="_blank" rel="noopener noreferrer">
            <img src={img2} className="img-fluid" alt="枯山水2" />
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="title mb-0">kare-san-sui</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="mb-0">2024</p>
          <p className="mb-0">水性塗料・重曹・スポンジ</p>
        </div>
        <div className="col-auto text-end align-self-end">
          <LikeButton id="2024-09_kare-san-sui" />
        </div>
      </div>
      <div className="row">
        <div className="col"> </div>
      </div>
    </>
  );
}
