import React from "react";
import { LikeButton } from "../components/LikeButton";
import pic from "./images/assortment_of_june_thumb.jpg";

export function SpecimenOfAutumn() {
  return (
    <>
      <div className="row mb-3">
        <div className="col">
          <a
            href="/images/2022-11_specimen-of-authumn/image.jpg"
            target="_blank"
          >
            <img
              src="/images/2022-11_specimen-of-authumn/image.jpg"
              className="img-fluid"
              alt="pict"
            ></img>
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="title mb-0">秋の標本/A Specimen of Autumn</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="mb-0">2022</p>
          <p className="mb-0">水性ペン・ワトソン紙</p>
          <p className="mb-0">727 mm x 727 mm</p>
        </div>
        <div className="col-auto text-end align-self-end">
          <LikeButton id="2022-11_specimen-of-authumn" />
        </div>
      </div>
    </>
  );
}
