import React from "react";
import { LikeButton } from "../../components/LikeButton";
import usePageTracking from "../../useTracking";

import img1 from "./images/img1.jpg";
import img2 from "./images/img2.jpg";
import largeimg1 from "./images/img1-large.jpg";
import largeimg2 from "./images/img2-large.jpg";

export default function Page() {
  usePageTracking();

  return (
    <>
      <div className="row mb-3">
        <div className="col mx-0 px-0">
          <iframe
            title="iframe"
            src="https://p5.porch.gallery/rainy-days-and.html"
            className="w-100"
            height={500}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="title mb-0">雨の日は/Rainy Days And...</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="mb-0">2023</p>
          <p className="mb-0">p5.js</p>
        </div>
        <div className="col-auto text-end align-self-end">
          <LikeButton id="2023-05_raindrops-are-falling" />
        </div>
      </div>
      <div className="row">
        <div className="col">水たまりの波紋が美しく広がります。</div>
      </div>
    </>
  );
}
