import React from "react";
import { LikeButton } from "../components/LikeButton";
import usePageTracking from "../useTracking";

export function Kawasemi() {
  usePageTracking();

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 gx-0">
          <a href="/images/2022-08_kawasemi/1.jpg" target="_blank">
            <img
              src="/images/2022-08_kawasemi/1s.jpg"
              className="img-fluid"
              alt="pict"
            ></img>
          </a>
        </div>
        <div className="col-4  gx-0">
          <a href="/images/2022-08_kawasemi/2.jpg" target="_blank">
            <img
              src="/images/2022-08_kawasemi/2s.jpg"
              className="img-fluid"
              alt="pict"
            ></img>
          </a>
        </div>
        <div className="col-4 gx-0">
          <a href="/images/2022-08_kawasemi/3.jpg" target="_blank">
            <img
              src="/images/2022-08_kawasemi/3s.jpg"
              className="img-fluid"
              alt="pict"
            ></img>
          </a>
        </div>
        <div className="col-4 gx-0">
          <a
            href="https://youtu.be/IxdDp1FeFGs"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/youtube.svg"
              className="img-fluid"
              alt="pict"
            ></img>
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="title mb-0">カワセミ/Kawasemi</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="mb-0">2022</p>
          <p className="mb-0">水性ボールペン・ケント紙</p>
          <p className="mb-0">500 mm x 606 mm</p>
        </div>
        <div className="col-auto text-end align-self-end">
          <LikeButton id="2022-08_kawasemi" />
        </div>
      </div>
    </>
  );
}
