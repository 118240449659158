import React from "react";
import { LikeButton } from "../components/LikeButton";
import usePageTracking from "../useTracking";

export function Rabbit() {
  usePageTracking();

  return (
    <>
      <div className="row mb-3">
        <div className="col-6  gx-0">
          <img
            src="/images/2023-01_rabbit/rabbit_l.jpg"
            className="img-fluid"
            alt="pict"
          ></img>
        </div>
        <div className="col-6  gx-0">
          <img
            src="/images/2023-01_rabbit/rabbit_s.jpg"
            className="img-fluid"
            alt="pict"
          ></img>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="title mb-0">うさぎ/Rabbit</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="mb-0">2023</p>
          <p className="mb-0">水性ペン・ワトソン紙</p>
          <p className="mb-0">500 mm x 606 mm/220 mm x 273 mm</p>
        </div>
        <div className="col-auto text-end align-self-end">
          <LikeButton id="2023-01_rabbit" />
        </div>
      </div>
      <div className="row">
        <div className="col">
          一本の線で描かれています。本作品は
          <a href="https://shop.porch.gallery/" className="mb-0">
            こちら
          </a>
          で販売しております。
        </div>
      </div>
    </>
  );
}
