import React from "react";
import { LikeButton } from "../../components/LikeButton";
import usePageTracking from "../../useTracking";

import img1 from "./images/img1.jpg";
import img2 from "./images/img2.jpg";
import largeimg1 from "./images/img1-large.jpg";
import largeimg2 from "./images/img2-large.jpg";

export default function Page() {
  usePageTracking();

  return (
    <>
      <div className="row mb-3">
        <div className="col-6 mx-0 px-0">
          <a href={largeimg1} target="_blank" rel="noopener noreferrer">
            <img src={img1} className="img-fluid" alt="シロクマ昼" />
          </a>
        </div>
        <div className="  col-6 mx-0 px-0">
          <a href={largeimg2} target="_blank" rel="noopener noreferrer">
            <img src={img2} className="img-fluid" alt="シロクマ夜" />
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="title mb-0">シロクマ/Polar Bear</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="mb-0">2023</p>
          <p className="mb-0">ケント紙</p>
        </div>
        <div className="col-auto text-end align-self-end">
          <LikeButton id="2023-08_polar-bear" />
        </div>
      </div>
      <div className="row">
        <div className="col">
          フランスの彫刻家、フランソワ・ポンポンによって制作された白熊(François
          Pompo, Polar Bear, 1909)をペーパークラフトで再現しました。
        </div>
      </div>
    </>
  );
}
