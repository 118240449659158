import React from "react";
import { LikeButton } from "../../components/LikeButton";
import usePageTracking from "../../useTracking";

import img1 from "./images/img1.png";
import img2 from "./images/img2.png";
import img3 from "./images/img3.png";

export default function Page() {
  usePageTracking();
  const youtube = "https://youtu.be/d31SIg-iyfM";
  return (
    <>
      <div className="row mb-3">
        <div className="col-4 mx-0 px-0">
          <a href={youtube} target="_blank" rel="noopener noreferrer">
            <img src={img1} className="img-fluid" alt="線香花火1" />
          </a>
        </div>
        <div className="col-4 mx-0 px-0">
          <a href={youtube} target="_blank" rel="noopener noreferrer">
            <img src={img2} className="img-fluid" alt="線香花火2" />
          </a>
        </div>
        <div className="col-4 mx-0 px-0">
          <a href={youtube} target="_blank" rel="noopener noreferrer">
            <img src={img3} className="img-fluid" alt="線香花火3" />
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="title mb-0">夏のおわり/End of Summer</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="mb-0">2024</p>
          <p className="mb-0">ドットマトリックスLED</p>
        </div>
        <div className="col-auto text-end align-self-end">
          <LikeButton id="2024-08_end-of-summer" />
        </div>
      </div>
      <div className="row">
        <div className="col">
          64x64のLEDで線香花火を表現しました。
          ※17:00から23:00までの間だけ表示されます。
        </div>
      </div>
    </>
  );
}
