import React from "react";
import { LikeButton } from "../../components/LikeButton";
import usePageTracking from "../../useTracking";

import img1 from "./images/img1.jpg";
import img2 from "./images/img2.jpg";
import largeimg1 from "./images/img1-large.jpg";
import largeimg2 from "./images/img2-large.jpg";

export function Egg() {
  usePageTracking();

  return (
    <>
      <div className="row mb-3">
        <div className="col-6 col-sm-6 mx-0 px-0">
          <a href={largeimg1}>
            <img src={img1} className="img-fluid" alt="pict"></img>
          </a>
        </div>
        <div className="col-6 col-sm-6 mx-0 px-0">
          <a href={largeimg2}>
            <img src={img2} className="img-fluid" alt="pict"></img>
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="title mb-0">たまご/An Egg</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="mb-0">2023</p>
          <p className="mb-0">ケント紙/LED</p>
        </div>
        <div className="col-auto text-end align-self-end">
          <LikeButton id="2023-05_an-egg" />
        </div>
      </div>
      <div className="row">
        <div className="col">
          昼と夜でまったく異なる表情を見せます。
          <br />
          <br />
          展開図は<a href="/images/2023-05_egg/egg.pdf">こちら</a>
          からダウンロードできます。
        </div>
      </div>
    </>
  );
}
