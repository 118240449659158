import React from "react";
import { LikeButton } from "../components/LikeButton";

export function AssortmentOfJune() {
  return (
    <>
      <div className="row mb-3">
        <div className="col">
          <a
            href="/images/2022-06_assortment_of_june/large.jpg"
            target="_blank"
          >
            <img
              src="/images/2022-06_assortment_of_june/thumb.jpg"
              className="img-fluid"
              alt="pict"
            ></img>
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="title mb-0">６月のアソート/Assortment of June</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="mb-0">2022</p>
          <p className="mb-0">アクリル・ワトソン紙</p>
          <p className="mb-0">803 mm x 1167 mm</p>
        </div>
        <div className="col-auto text-end align-self-end">
          <LikeButton id="2022-06_assortment-of-june" />
        </div>
      </div>
    </>
  );
}
