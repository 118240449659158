import React from "react";
import { LikeButton } from "../components/LikeButton";
import usePageTracking from "../useTracking";

export function Fish() {
  usePageTracking();

  return (
    <>
      <div className="row mb-3">
        <div className="col-4 col-sm-4 gx-3">
          <a href="https://p5.porch.gallery/school-of-fish.html">
            <img
              src="/images/2023-03_school-of-fish/1.png"
              className="img-fluid"
              alt="pict"
            ></img>
          </a>
        </div>
        <div className="col-4 col-sm-4 gx-3">
          <a href="https://p5.porch.gallery/school-of-fish.html">
            <img
              src="/images/2023-03_school-of-fish/3.png"
              className="img-fluid"
              alt="pict"
            ></img>
          </a>
        </div>
        <div className="col-4 col-sm-4 gx-3">
          <a href="https://p5.porch.gallery/school-of-fish.html">
            <img
              src="/images/2023-03_school-of-fish/4.png"
              className="img-fluid"
              alt="pict"
            ></img>
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="title mb-0">A School of Fish</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="mb-0">2023</p>
          <p className="mb-0">シングルボードコンピューター/p5.js</p>
        </div>
        <div className="col-auto text-end align-self-end">
          <LikeButton id="2023-03_school-of-fish" />
        </div>
      </div>
      <div className="row">
        <div className="col">
          それぞれの魚が「近くの群れに集まる」「他の魚と向きを揃える」「他の魚と近づきすぎない」という３つのルールに従うだけで、まるで本物の群れのように振る舞います。15秒ごとに３つのルールの強さが変わり、違った表情を見せます。
          <a href="https://p5.porch.gallery/school-of-fish.html">こちら</a>
          よりお手元で見ることができます。
        </div>
      </div>
    </>
  );
}
