import React, { useState } from "react";
import { LikeButton } from "../../components/LikeButton";
import usePageTracking from "../../useTracking";

import img1 from "./images/img1.jpg";
import img2 from "./images/img2.jpg";
import largeimg1 from "./images/img1-large.jpg";
import largeimg2 from "./images/img2-large.jpg";

import { getStorage, ref, uploadBytes } from "firebase/storage";

export default function Page() {
  usePageTracking();

  const [images, setImages] = useState<File[]>([]);
  const [state, setState] = useState<"select" | "uploaded" | "error">("select");
  const [commentText, setCommentText] = useState<string>("");
  const inputId = Math.random().toString(32).substring(2);

  const handleOnAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    if (!e.target.files) return;
    setImages([...images, ...e.target.files]);
  };

  const handleOnRemoveImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const handleOnSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();

    const datestring = new Date().toISOString().replace(/[-:]/g, "");
    const storage = getStorage();
    const metadata = {
      customMetadata: {
        comment: commentText,
      },
    };
    await Promise.all(
      images.map((image) => {
        const filename = image.name;
        const storageRef = ref(storage, "upload/" + datestring + filename);

        // 'file' comes from the Blob or File API
        return uploadBytes(storageRef, image, metadata);
      })
    );
    setState("uploaded");

    // setIsCommentSending(false);
  };

  const backToHome = () => {
    setImages([]);
    setCommentText("");
    setState("select");
  };

  return (
    <div className="container-fluid" style={{ maxWidth: 500 }}>
      <div className="row mb-3 justify-content-center">
        <div className="col-8 mx-0 px-0">
          <img src={img1} className="img-fluid" alt="ひまわり" />
        </div>
      </div>
      <p>
        ひまわりが咲いたら写真をアップロードしてください。写真が集まったら展示します。
      </p>
      {state === "select" && (
        <>
          <div className="row">
            <div className="col">
              <form action="" onSubmit={(e) => handleOnSubmit(e)}>
                <textarea
                  name="comment"
                  className="form-control mb-3"
                  placeholder="コメント欄"
                  onChange={(e) => setCommentText(e.target.value)}
                ></textarea>
                <div className="d-flex justify-content-between  mb-3 text-sm">
                  <label htmlFor={inputId} className="btn btn-light round-3">
                    <i className="bi bi-camera me-2"></i>
                    画像を追加
                    <input
                      id={inputId}
                      type="file"
                      multiple
                      accept="image/*,.png,.jpg,.jpeg,.gif"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleOnAddImage(e)
                      }
                      style={{ display: "none" }}
                    />
                  </label>
                  <button
                    type="submit"
                    className="btn btn-light"
                    disabled={images.length < 1}
                  >
                    <i className="bi bi-send me-2"></i>
                    送信
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="card-group">
            {images.map((image, i) => (
              <div key={i} className="card text-right">
                <img
                  src={URL.createObjectURL(image)}
                  className="card-img rounded"
                  alt={image.name}
                />
                <button
                  className="card-img-overlay btn-close ms-2 mt-2"
                  onClick={() => handleOnRemoveImage(i)}
                ></button>
              </div>
            ))}
          </div>
        </>
      )}
      {state === "uploaded" && (
        <>
          <div>
            アップロードありがとうございました。
            <br />
          </div>
          <div className="text-right">
            <button className="btn btn-secondary btn-sm" onClick={backToHome}>
              戻る
            </button>
          </div>
        </>
      )}
    </div>
  );
}
